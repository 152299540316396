import Loader from "@/components/Loader";
import NavBar from "@/components/NavBar";
import { TokenStatusEnum } from "@/enums";
import assetModel from "@/models/asset.model";
import globalModel from "@/models/global.model";
import marketModel from "@/models/market.model";
import { hideString } from "@/utils/string";
import { useReactive, useRequest } from "ahooks";
import { useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Dialog, Image, ImagePreview, Toast } from "react-vant";
import { useSnapshot } from "valtio";
import styles from "./index.module.css";

export default () => {
  const state = useReactive({
    scrollTop: 0,
    visible: false,
  });
  const { user } = useSnapshot(globalModel.state);
  const nav = useNavigate();
  const params = useParams();
  const { data, refreshAsync } = useRequest(
    () => {
      return assetModel.getToken(Number(params.id)).then((res) => res.data);
    },
    {
      ready: !!params.id,
      refreshDeps: [params.id],
    }
  );
  const isOwner = useMemo(() => user?.id === data?.ownerId, [user, data]);
  return (
    <div className={styles.page}>
      <NavBar
        theme="none"
        placeholder={false}
        title={state.scrollTop > 100 ? data?.asset?.title : ""}
        onScroll={(e) => (state.scrollTop = e)}
      />
      {!data ? (
        <Loader />
      ) : (
        <>
          <Image
            className={styles.image}
            src={data.asset?.image}
            fit="cover"
            onClick={() =>
              ImagePreview.open({
                showIndex: false,
                lazyload: true,
                images: [data.asset?.image],
              })
            }
          />
          <div className={styles.asset}>
            <div className={styles.title}>
              {data.asset?.title}#{data?.no}
            </div>
            <div className={styles.tags}>
              <div className={styles.limit}>
                <span className={styles.label}>限量</span>
                <span className={styles.num}>{data.asset?.limit}份</span>
              </div>
              <div className={styles.limit}>
                <span className={styles.label}>流通</span>
                <span className={styles.num}>{data.asset?.circulation}份</span>
              </div>
            </div>
          </div>

          <div className={styles.publisher}>
            <div className={styles.left}>
              <Image
                className={styles.logo}
                src={data.asset?.publisher?.logo}
              />
              <div className={styles.info}>
                <div className={styles.label}>发行方</div>
                <div className={styles.name}>{data.asset?.publisher?.name}</div>
              </div>
            </div>
            <div className={styles.right}>
              <Image className={styles.logo} src={data.asset?.creator?.logo} />
              <div className={styles.info}>
                <div className={styles.label}>创作者</div>
                <div className={styles.name}>{data.asset?.creator?.name}</div>
              </div>
            </div>
          </div>

          <div className={styles.section}>
            <div className={styles.title}>基础信息</div>
            <div className={styles.item}>
              <div className={styles.label}>认证标识:</div>
              <div className={styles.value}>{data.id}</div>
            </div>
            <div className={styles.item}>
              <div className={styles.label}>合约标准:</div>
              <div className={styles.value}>ERC721</div>
            </div>
            <div className={styles.item}>
              <div className={styles.label}>资产哈希:</div>
              <div className={styles.value}>
                {hideString(data.hash, 16, 16)}
              </div>
            </div>
            <div className={styles.item}>
              <div className={styles.label}>所属专辑:</div>
              <div className={styles.value}>{data.asset?.album?.title}</div>
            </div>
          </div>

          <div className={styles.section}>
            <div className={styles.title}>特别提示</div>
            <div className={styles.list}>
              <div className={styles.item}>
                数字资产为虚拟数字商品，而非实物，仅限18周岁(不含)以上65周岁(含)以下的中国大陆用户购买。数字资产的版权由发行方或原创作者拥有，除另行取得版权拥有者书面同意外，用户不得将数字资产用于任何商业用途。本商品一经售出，不支持退换。
              </div>
            </div>
          </div>

          <div className={styles.footer}>
            <div className={styles.price}>
              <span>￥</span>
              {data.price}
            </div>
            {data.status === TokenStatusEnum.LOCKING && (
              <Button className={styles.cancel} type="primary" block disabled>
                支付锁定中...
              </Button>
            )}
            {data.status === TokenStatusEnum.HOLD && isOwner && (
              <>
                <Button
                  className={styles.submit}
                  type="primary"
                  block
                  disabled={!data.asset.isTradable}
                  onClick={() => {
                    nav(`/sale/${data.id}`);
                  }}
                >
                  {data.asset.isTradable ? "立即寄售" : "暂未开放寄售"}
                </Button>
              </>
            )}
            {data.status === TokenStatusEnum.ON_SALE ? (
              isOwner ? (
                <>
                  <Button
                    className={styles.cancel}
                    block
                    onClick={async () => {
                      await Dialog.confirm({
                        title: "确定要取消寄售吗?",
                      });
                      const update = Toast({
                        type: "loading",
                        message: "正在提交...",
                        forbidClick: true,
                        duration: 0,
                      });
                      const res = await assetModel.offSale(Number(params.id));
                      if (res?.code === 200) {
                        await refreshAsync();
                        update.config({
                          type: "success",
                          message: "取消成功",
                          forbidClick: true,
                        });
                      }
                      setTimeout(() => {
                        update.clear();
                      }, 2000);
                    }}
                  >
                    取消寄售
                  </Button>
                </>
              ) : (
                <>
                  <Button
                    className={styles.submit}
                    type="primary"
                    block
                    onClick={async () => {
                      const update = Toast({
                        type: "loading",
                        message: "正在提交...",
                        forbidClick: true,
                        duration: 0,
                      });
                      const res = await marketModel.order({
                        id: data.asset.id,
                        tokenId: data.id,
                      });
                      if (res?.code === 200) {
                        await refreshAsync();
                        update.config({
                          type: "success",
                          message: "下单成功",
                          forbidClick: true,
                        });
                        if (!!res.data?.no) {
                          nav(`/order/detail/${res.data?.no}`);
                        }
                      }
                      setTimeout(() => {
                        update.clear();
                      }, 2000);
                    }}
                  >
                    立即购买
                  </Button>
                </>
              )
            ) : (
              <></>
            )}
          </div>
        </>
      )}
    </div>
  );
};
